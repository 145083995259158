<template>
    <div>
        <Navs></Navs>
        <router-view />
        <Bottom></Bottom>
    </div>
</template>

<script>
export default {
    name: "Layout"
}
</script>

<style scoped>

</style>
