import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css';
import httpRequest from '@/api/home.js';        //全局引入请求
import Navs from "@/views/components/navs"
import Bottom from "@/views/components/bottom"

const app = createApp(App);

app.component('Navs', Navs);
app.component('Bottom', Bottom);

//全局配置
app.config.globalProperties.$http=httpRequest;        //vue3生命全局名称是config.globalProperties.名称 使用全局名称建议使用proxy.$http
app.config.globalProperties.$baseUrl=process.env.VUE_APP_BASE_API
app.use(router).use(ElementPlus).mount('#app')
