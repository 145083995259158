import axios from "axios";
import qs from "qs";
import { ElMessage } from 'element-plus';
const env = require('../config/env')
let requestUrl=window.location.href
// axios.defaults.baseURL =process.env.VUE_APP_BASE_API  //正式
axios.defaults.baseURL = env.BASE_API
//post请求头
axios.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//响应拦截
axios.interceptors.response.use(
  response => {
    if (response.data.code == 200) {
      return Promise.resolve(response);
    } else {
      ElMessage.error(response.data.msg)
      return Promise.reject(response);
    }
  },
  error => {
  	alert(`异常请求：服务器错误`)
  }
);
export default {
  post(url, data) {
    return new Promise((resolve, reject) => {

      axios({
          method: 'post',
          url: url,
          data: data, //qs.stringify(data),
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        });
    })
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
          method: 'get',
          url: url,
          params: data,
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};

