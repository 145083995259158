<template>
    <div class="bottom-box">
        <div class="service-box">
            <div class="itme" @click="$router.push('/yuyue')">聯繫我們</div>
            <div class="itme">隱私政策</div>
            <div class="itme">服務條款</div>
            <div class="itme">服務安全性</div>
        </div>
        <div class="line"></div>
        <div class="icon-box">
            <a class="i" href="https://www.facebook.com/profile.php?id=100091895533797&is_tour_completed=true" target="_blank">
                <img class="bannerImg" src="@/assets/image/index/b-1.png" alt="facebook">
            </a>
            <a class="i" href="https://www.youtube.com/@SPEEDGLOBALSIGN" target="_blank">
                <img class="bannerImg" src="@/assets/image/index/b-2.png" alt="youtube">
            </a>
            <el-popover
                placement="top-start"
                width="260"
                trigger="hover">
                <img style="width: 235px; height: 270px;" class="bannerImg" src="@/assets/image/index/instagram_qr.png" alt="instagram">
                <template #reference>
                    <a class="i">
                        <img class="bannerImg" src="@/assets/image/index/b-3.png" alt="instagram">
                    </a>
                </template>
            </el-popover>
<!--            <a class="i" href="https://www.instagram.com/speedyglobalsign/" target="_blank">
                <img class="bannerImg" src="@/assets/image/index/b-3.png" alt="instagram">
            </a>-->
            <a class="i" href="https://www.linkedin.com/in/%E5%BF%85%E7%B0%BD-%E4%BA%8B-0a8142273/" target="_blank">
                <img class="bannerImg" src="@/assets/image/index/b-4.png" alt="linkedin">
            </a>
            <a class="i" href="https://twitter.com/SpeedGlobalSign" target="_blank">
                <img class="bannerImg" src="@/assets/image/index/b-5.png" alt="twitter">
            </a>
            <a class="i" href="https://weibo.com/u/7835330259?tabtype=feed" target="_blank">
                <img class="bannerImg" src="@/assets/image/index/b-6.png">
            </a>
<!--            <div class="i">
                <img class="bannerImg" src="@/assets/image/index/b-7.png">
            </div>
            <div class="i">
                <img class="bannerImg" src="@/assets/image/index/b-8.png">
            </div>-->
        </div>
        <div class="info">
            Copyright © Guanfu Consume Financial Information Technology Co.,Limited<br />
            Power by：China Consume Financial Holdings Co., Ltd<br />
            GOVERNING LAW：All the items above are organized and regulated under the laws of Hong Kong
        </div>
        <div class="bottom-strip">About <span class="a" @click="toIndex">SpeedyGlobalSign</span> <span class="a" @click="toReservation">Contact us</span>
            <span style="padding: 0 5px;"></span><img class="bannerImg" src="@/assets/image/index/pci2.png">
        </div>
    </div>
</template>

<script>
export default {
    name: "buttom",
    methods: {
        // 跳转到首页
        toIndex() {
            window.open('https://pc.speedyglobalsign.com.cn', '_blank')
        },
        toReservation() {
            this.$router.push('/yuyue')
        }
    }
}
</script>

<style scoped>
.bottom-box {
    background: #F7FBFD;
    padding: 0.4rem 0 0 0;
}
.bottom-box .itme{
    color: #146899;
}
.service-box{
    color: #146899;
    font-size: 0.24rem;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0.3rem 0;
}
.service-box .itme{
    width: 1.8rem;
    cursor: pointer;
}
.icon-box{
    display: flex;
    justify-content: center;
    padding: 0.3rem 0 0.6rem 0;
}
.icon-box .i{
    width: 0.46rem;
    height: 0.46rem;
    margin-right: 0.3rem;
    cursor: pointer;
    display: block;
}
.icon-box .i img{
    width: 100%;
    height: 100%;
}
.bottom-box .line{
    width: 14rem;
    background: #146899;
    height: 1px;
    margin: auto;
}
.bottom-box .info{
    text-align: center;
    color: #146899;
    padding-bottom: 0.24rem;
}
.bottom-strip {
    background: #0A68A2;
    height: 0.7rem;
    line-height: 0.7rem;
    text-align: center;
    color: #ffffff;
}
.bottom-strip .a{
    color: #59DFAC;
    cursor: pointer;
    text-decoration:underline;
}
</style>
