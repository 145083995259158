<template>
    <div style="height: 0.8rem;">
        <div class="navs">
            <div class="navs-content">
                <div class="logo-box" @click="toIndex">
                    <img class="bannerImg" src="@/assets/image/index/logo.png">
                </div>
                <div class="navs-right">
                    <div class="items">
                        <div class="item">
                            <router-link to="/chanping">
                                <span :class='{"active": active == 1}'>產品特點</span>
                            </router-link>
                        </div>
                        <div class="item">
                            <el-dropdown>
                                <div class="item-drop">
                                    <span :class='{"active": active == 2}'>解決方案</span>
                                    <div class="item-down-icon">
                                        <img src="@/assets/image/index/icon_down.png" />
                                    </div>
                                </div>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item v-for="(item, index) in dropDownList" :key="index">
                                            <span @click="toRouter(item.path)">{{ item.name }}</span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                        <div class="item">
                            <router-link to="/jiage">
                                <span :class='{"active": active == 3}'>價格方案</span>
                            </router-link>
                        </div>
                        <div class="item">
                            <router-link to="/yuyue">
                                <span :class='{"active": active == 4}'>預約演示</span>
                            </router-link>
                        </div>
                        <div class="item">
                            <router-link to="/xiazai">
                                <span :class='{"active": active == 5}'>下載</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="btn" @click="toIndex2">登錄/註冊</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useRouter } from 'vue-router'
import router from '../../router'
export default {
    name: "navs",
    data() {
        return {
            dropDownList: [
                {name: "支付行業", path: "/zhifu"},
                {name: "行業應用軟體行業", path: "/ruanti"},
                {name: "律所行業", path: "/lvshi"},
                {name: "跨境供應鏈行業", path: "/gongyinglian"},
                {name: "銀行、政企服務行業", path: "/zhengwu"},
                {name: "地產行業", path: "/dichan"},
            ],
            currentSolve: {name: "支付行業", path: "/zhifu"},
            active: null
        }
    },
    watch: {
        "$route": {
            handler(route){
                const name = route.meta.name;
                if (route.meta && route.meta.name == 'jiejue') {
                    const currentSolve = this.dropDownList.find(item => item.path == route.path)
                    currentSolve && (this.currentSolve = currentSolve);
                }
                if (name == "chanping") {
                    this.active = 1
                }else if (name == "jiejue"){
                    this.active = 2
                }else if (name == "jiage"){
                    this.active = 3
                }else if (name == "yuyue"){
                    this.active = 4
                }else if (name == "xiazai"){
                    this.active = 5
                }else {
                    this.active = name
                }
            },
            immediate: true
        }
    },
    mounted() {
        console.log("---->")
    },
    methods: {
        toIndex() {
            this.$router.push('/home')
        },
        toIndex2() {
            window.open('https://pc.speedyglobalsign.com.cn', '_blank')
        },
        toRouter(path) {
            console.log("path--->", path)
            path && this.$router.push(path)
        }
    },
    setup() {

    }
}
</script>

<style scoped>
.navs{
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 11;
    height: 0.8rem;
    background: #ffffff;
}
.navs-content {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 5px 5px -4px rgba(0, 0, 0, .1)
}
.logo-box{
    width: 1.41rem;
    height: 0.49rem;
    margin-left: 0.77rem;
    margin-top: 0.19rem;
    cursor: pointer;
}
.logo-box img{
    width: 100%;
    height: 100%;
}
.items {
    display: flex;
    font-size: 0.16rem;
    padding-right: 0.5rem;
}
.items .item{
    height: 0.79rem;
    /*width: 1.2rem;*/
    padding: 0 0.28rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navs-right{
    display: flex;
    margin-right: 1.2rem
}
.navs-right .btn{
    background: url("../../assets/image/btn.png") no-repeat;
    width: 1.1rem;
    height: 0.42rem;
    line-height: 0.42rem;
    color: #ffffff;
    text-align: center;
    border-radius: 0.05rem;
    margin-top: 0.19rem;
    cursor: pointer;
}
.items .item /deep/ .item-drop {
    display: flex;
    font-size: 0.16rem;
}
.item-down-icon{
    width: 0.1rem;
    height: 0.08rem;
    margin-left: 0.03rem;
}
.item-down-icon img {
    width: 100%;
    height: 100%;
}
.active{
    color: #2277B9;
}
.items .item /deep/ .item-drop:focus{
    outline: none;
}

</style>
