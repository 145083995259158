let BASE_API = "https://qcjj.sicpay.com/website/api";		    // 生产
BASE_API = "http://192.168.10.172:8080"
BASE_API = "https://devmall.sicpay.com/website/api"

// 打包不用写死域名
if (process.env.NODE_ENV === 'production') {
  // let requestUrl=window.location.href
  // BASE_API = requestUrl.substring(0,(requestUrl.indexOf('.com')+4)) +'/website/api'
    BASE_API = "/website/api"
}

module.exports = {
  BASE_API
}
