<template>

    <router-view />
</template>
<script>
import { companyInfo } from "@/api/home";
export default {
    async created() {
        /*let res = await companyInfo();
        if (res.code == 200) {
            localStorage.setItem(
                "bottomInfo_key",
                JSON.stringify(res.data.bottomBody)
            );
            localStorage.setItem(
                "serverInfo_key",
                JSON.stringify(res.data.cusServiceBody)
            );
            if (res.data.baseInfoBody) {
                localStorage.setItem(
                    "baseInfo_key",
                    JSON.stringify(res.data.baseInfoBody)
                );
                document
                    .querySelector("body")
                    .style.setProperty(
                        "--themeColor",
                        res.data.baseInfoBody.mainColor
                            ? res.data.baseInfoBody.mainColor
                            : "#3C83FF"
                    );
                this.changeFavicon(res.data.baseInfoBody.labelIcon);
                document.title = res.data.baseInfoBody.siteName;
            }
        }*/
    },
    methods: {
        changeFavicon(link) {
            let $favicon = document.querySelector('link[rel="icon"]');
            if ($favicon !== null) {
                $favicon.href = process.env.VUE_APP_BASE_API + link;
            } else {
                $favicon = document.createElement("link");
                $favicon.rel = "icon";
                $favicon.href = link;
                document.head.appendChild($favicon);
            }
        },
    }
};
</script>

<style lang="less">
@import url("@/assets/css/common.css");
</style>
