import request from '@/utils/request.js';

//获取基本信息
export function companyInfo() {
    return request.get('/pc/info/query');
};

//获取导航栏信息
export function getNavData() {
    return request.get('/pc/nav_bar/query');
};

//获取首页模板
export function getHomePageData() {
    return request.get('/pc/index_set/get_home');
};

//根据ID获取页面模板
export function getPageData(id) {
    return request.get(`/pc/index_set/${id}`);
};

//FAQ分类列表
export function getFAQTypeList() {
    return request.get('/pc/faq_center/type_list');
};

//FAQ数据列表
export function getFAQList(params) {
    return request.get('/pc/faq_center/list',params);
};

//FAQ列表详情
export function getFAQDetail(id) {
    return request.get(`pc/faq_center/${id}`);
};

//咨询中心列表
export function getConsultList(params) {
    return request.get('/pc/cou_center/list',params);
};

//咨询中心列表详情
export function getConsultListDetail(id) {
    return request.get(`/pc/cou_center/${id}`);
};


//咨询中心列表详情
export function addUser(data= {}) {
    return request.post(`/admin/user/reserve/add`, data);
};
